import React from "react";
import styles from './Contact.module.css';

export default function Contact() {
  return (
    <div className={styles.centerOnPage}>
      Contact Page
    </div>
  );
}
